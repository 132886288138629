import * as React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import PageHeader from "../../components/pageheader"
import DevSingle from "../../components/devsingle"
import { extractSrcFromIframe } from "../../utils/utils"

const DevelopmentsTypeSiteplanPage = ({ pageContext }) => {
    const data = pageContext?.data
    let prev = undefined
    let next = undefined

    if(data?.nearby.length > 1) {
        data?.nearby.forEach((item, key) => {
            if(key === 0) {
                prev = {
                    link: `${pageContext?.basicPath}/${item?.slug}`,
                    label: item?.name,
                    bgImage: item?.image,
                }
            } else {
                next = {
                    link: `${pageContext?.basicPath}/${item?.slug}`,
                    label: item?.name,
                    bgImage: item?.image,
                }
            }
        })
    }
    return (
        <Layout isTestimonialsHidden activeMenuId={3}>
            <Seo title={data?.title} />
            {data?.blocks.map((item, i) => {
                switch (item?.name) {
                    case "acf/cover-main":
                        const coverMain = item?.coverMain
                        return (
                            <PageHeader
                                key={i}
                                title={coverMain?.title}
                                subTitle={coverMain?.text}
                                image={coverMain?.img?.mediaItemUrl}
                                breadcrumbs={[
                                    {
                                        label: `Developments / ${pageContext?.propTypes?.name}`,
                                        link: pageContext?.basicPath,
                                    },
                                    { label: coverMain?.title, link: null },
                                ]}
                            />
                        )
                    case "acf/acfgallery":
                        const gallery = item?.acfBlockGallery
                        const embedMap = data?.blocks.find(
                            item => item.name == "core/html"
                        )?.originalContent
                        if (gallery?.title === "Siteplan") {
                            return (
                                <DevSingle
                                    key={i}
                                    title={data?.title}
                                    mapSrc={embedMap ? extractSrcFromIframe(embedMap) : null}
                                    activePage="Siteplan"
                                    pageLinks={pageContext?.pageSideLink}
                                    prev={prev}
                                    next={next}
                                >
                                    <h2 className="mx-4 lg:mx-0 font-serif text-4xl mb-8">
                                        Siteplan
                                    </h2>
                                    {gallery?.images.map((f, i) => {
                                        return (
                                            <div
                                                key={i}
                                                className="mb-8 mx-4 lg:mx-0"
                                            >
                                                <img
                                                    src={f?.mediaItemUrl}
                                                    alt={f?.altText}
                                                />
                                            </div>
                                        )
                                    })}
                                </DevSingle>
                            )
                        }
                }
            })}
        </Layout>
    )
}

export default DevelopmentsTypeSiteplanPage
